<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <SignUp />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SignUp from "../components/login/SignUp";
export default {
  name: "SignUpView",
  components: {
    SignUp,
  },
};
</script>
