import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import firebase from 'firebase'

Vue.config.productionTip = false
var firebaseConfig = {
  apiKey: 'AIzaSyDYtvrAbgoBx6YyDI_G2AnStRg8nxAiTrA',
  authDomain: 'epoc-2020.firebaseapp.com',
  databaseURL: 'https://epoc-2020.firebaseio.com',
  projectId: 'epoc-2020',
  storageBucket: 'epoc-2020.appspot.com',
  messagingSenderId: '734056796914',
  appId: '1:734056796914:web:fcaa0d9697ea8de778766b',
  measurementId: 'G-EGSNJHTBMX'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
