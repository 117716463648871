<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, itemKey) in $store.state.history" :key="itemKey">
        <v-expansion-panel-header>
          {{new Date(item.date.seconds*1000)}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h1>Estas son las recomendaciones del día {{new Date(item.date.seconds*1000).getDate()}}-{{new Date(item.date.seconds*1000).getMonth() + 1}}-{{new Date(item.date.seconds*1000).getFullYear()}} / {{new Date(item.date.seconds*1000).getHours()}}:{{new Date(item.date.seconds*1000).getMinutes()}}</h1>
            <v-card-text
              v-for="(recomendation) in item.feedBack"
              :key="recomendation.key"
              name="input-7-1"
              label="Default style"
              hint="Hint text"
              solo
            >
            {{recomendation}}
            </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped></style>
