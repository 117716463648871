<template>
  <div>
    <v-card class="mx-auto"  outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Información personal</div>
          <v-list-item-title class="text-h5 mb-1">
            {{user.name}}
          </v-list-item-title>
          <v-list-item-subtitle
            >Edad: {{user.age}} años</v-list-item-subtitle
          ><v-list-item-subtitle
            >IMC: {{user.weight / ((user.height/100)^2)}}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            >Sexo: {{user.sex}}</v-list-item-subtitle
          >
        </v-list-item-content>

        <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar>
      </v-list-item>

    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
