<template>
  <v-container class="ma-15">
    <v-row>
      <v-col cols="5" lg="3">
        <UserList @selected-user="getUserClinicalData" :users="users" />
      </v-col>

      <v-col cols="7" lg="9">
        <v-row>
          <v-col cols="12" lg="4">
            <v-row>
              <v-col cols="12">
                <UserDetail :user="selectedUser" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <UserHistorial
                  @selected-entry="setEntry"
                  :id="selectedUser.id"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="12">
                <LinesChartCard
                  v-if="selectedUserHistorial.length > 0"
                  :selectedUserHistorial="selectedUserHistorial"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <HistorialEntry :data="selectedEntry" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import UserList from "@/components/clinical/UserList.vue";
import HistorialEntry from "@/components/clinical/HistorialEntry.vue";
import UserDetail from "@/components/clinical/UserDetail.vue";
import UserHistorial from "@/components/clinical/UserHistorial.vue";
import LinesChartCard from "@/components/clinical/charts/LinesChartCard.vue";
export default {
  components: {
    UserList,
    UserDetail,
    UserHistorial,
    HistorialEntry,
    LinesChartCard,
  },
  async mounted() {
    await this.getUserList();
  },
  data() {
    return {
      users: [],
      selectedUser: {},
      selectedUserHistorial: [],
      selectedEntry: {},
    };
  },
  methods: {
    async getUserList() {
      var context = this;
      var db = firebase.firestore();
      await db
        .collection("users")
        .get()
        .then(async function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log("doc");
            console.log(doc.id);
            var user = doc.data();
            user.id = doc.id;
            context.users.push(user);
          });
        })
        .catch(async function (error) {
          console.log(error);
        });
    },
    getUserClinicalData(user) {
      console.log("getUserClinicalData");
      console.log(user);
      this.selectedUser = user;
      this.getHistorial(user.id);
    },
    async getHistorial(id) {
      var context = this;
      var db = firebase.firestore();
      await db
        .collection(id)
        .get()
        .then(async function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            var entry = doc.data();
            entry.id = doc.id;
            context.selectedUserHistorial.push(entry);
          });
        })
        .catch(async function (error) {
          console.log(error);
        });
    },
    setEntry(data) {
      console.log("setEntry");
      console.log(data);
      this.selectedEntry = data;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
