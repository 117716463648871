import firebase from 'firebase'
const actions = {
  async setHistoryAsync (context) {
    context.commit('setLoadingFlag', true)
    var db = firebase.firestore()
    var historyDocs = []
    await db
      .collection(firebase.auth().currentUser.uid)
      .get()
      .then(async function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          historyDocs.push(doc.data())
        })
        await context.commit('setHistory', historyDocs)
        context.commit('setLoadingFlag', false)
      })
      .catch(async function (error) {
        console.log(error)
        context.commit('setLoadingFlag', true)
      })
  }
}
export default actions
