<template>
  <div>
    <v-card class="mx-auto" max-width="400" outlined elevation="20">
      <v-progress-linear
        indeterminate
        color="secondary"
        v-if="$store.state.loading"
      ></v-progress-linear>
      <v-form ref="form">
        <v-container>
          <v-card-title>Login</v-card-title>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  id="userInput"
                  v-model="userData.email"
                  :rules="userRules"
                  label="user"
                  required
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  id="passInput"
                  v-model="userData.password"
                  :rules="passRules"
                  :counter="20"
                  label="pass"
                  required
                  type="password"
                ></v-text-field>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn id="loginButton" block color="secondary" dark @click="login()">{{
                  $t('login')
                }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-list-item>
                  <v-list-item-content>
                    <a>No tienes cuenta? Contacta con el administrador</a>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import firebase from 'firebase'
export default {
  name: 'Login',
  data: () => ({
    userData: {
      email: 'enriquesanchezbonet@gmail.com',
      password: 'covid1984'
    },
    confirmationCode: '',
    userRules: [v => !!v || 'User is required'],
    passRules: [v => !!v || 'Password is required'],
    snackbarText: 'Error',
    snackbarColor: 'error',
    snackbar: false,
    loading: false,
    dialog: false
  }),
  methods: {
    async login () {
      this.$store.commit('setLoadingFlag', true)
      var context = this
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.userData.email, this.userData.password)
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code
          var errorMessage = error.message
          console.log(errorCode)
          console.log(errorMessage)
          context.$store.commit('setLoadingFlag', false)
          // ...
        })
      this.$router.push('/home')
    }
  }
}
</script>
