import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#44b8b8',
        secondary: '#42A5F5',
        accent: '#8c9eff',
        error: '#b71c1c'
      },
      dark: {
        primary: '#4d4d4d',
        secondary: '#424242',
        accent: '#8c9eff',
        error: '#b71c1c'
      }
    }
  }
})
