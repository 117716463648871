<template>
  <div>
    <v-card class="pa-md-4 mx-auto" outlined elevation="19" shaped>
      <v-container>
        <v-progress-linear
          indeterminate
          color="secondary"
          v-if="$store.state.loading"
        ></v-progress-linear>
        <v-stepper v-model="e6" vertical>
          <div
            v-for="(segmentPatientData, index) in patientDataList"
            :key="segmentPatientData.key"
          >
            <v-stepper-step :complete="e6 > index" :step="index">
              {{ dataCategories[index] }}
              <small>Por favor complete la informacion</small>
            </v-stepper-step>
            <v-stepper-items>
              <v-stepper-content :step="index">
                <v-row>
                  <v-col>
                <v-btn color="primary" @click="e6 = index + 1">
                  Continue
                </v-btn>
                <v-btn text @click="e6 = 0"> Cancel </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    :sm="
                      patientDataList[index][itemKey].dataType === 'comboBox'
                        ? 12
                        : 4
                    "
                    v-for="(item, itemKey) in segmentPatientData"
                    :key="item.key"
                    align="center"
                    justify="center"
                  >
                    <div
                      v-if="
                        typeof patientDataList[index][itemKey].value ===
                        'boolean'
                      "
                    >
                      <v-checkbox
                        v-if="
                          patientDataList[index][itemKey].dataType !==
                          'comboBox'
                        "
                        v-model="patientDataList[index][itemKey].value"
                        :label="$t(itemKey)"
                        :disabled="
                          patientDataList[index][itemKey].type !== 'paciente'
                        "
                        required
                      ></v-checkbox>
                    </div>
                    <div
                      v-if="
                        typeof patientDataList[index][itemKey].value !==
                        'boolean'
                      "
                    >
                      <v-text-field
                        outlined
                        v-if="
                          patientDataList[index][itemKey].dataType !==
                          'comboBox'
                        "
                        v-model="patientDataList[index][itemKey].value"
                        :label="$t(itemKey)"
                        :disabled="
                          patientDataList[index][itemKey].type !== 'paciente'
                        "
                        required
                      ></v-text-field>
                    </div>

                    <div
                      v-if="
                        patientDataList[index][itemKey].dataType === 'comboBox'
                      "
                    >
                      <v-combobox
                        v-if="
                          patientDataList[index][itemKey].dataType ===
                          'comboBox'
                        "
                        v-model="patientDataList[index][itemKey].value"
                        :items="comorbilidades"
                        label="Charlson"
                        multiple
                        return-object
                        chips
                      >
                      </v-combobox>
                    </div>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </div>
        </v-stepper>
        <v-row v-if="e6 == patientDataList.length">
          <v-col cols="8">
            <v-btn
              id="storeData"
              block
              color="secondary"
              dark
              @click="storeData()"
              >{{ $t('storeData') }}</v-btn
            >
          </v-col>
          <v-col cols="2">
            <v-btn id="storeData" block dark @click="e6 = 0">{{
              $t('reset')
            }}</v-btn>
          </v-col>
                    <v-col cols="2">
            <v-btn id="storeData" block @click="generateFacts()">{{
              $t('generate facts')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800">
      <v-card>
        <v-card-title class="headline">
          Esta es la recomendación para tu cuadro clínico
        </v-card-title>
        <v-card-text :key="item.key" v-for="item in recomendations">{{
          item
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Entendido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase'
import Engine from 'json-rules-engine'
import { addRules } from '@/services/addrules-service'
export default {
  name: 'DataInput',
  data: () => ({
    dialog: false,
    // sinDatoHabitoTabaco: true,
    recomendations: [],
    patientDataList: [
      {
        edad: { value: 71, type: 'paciente' },
        sexo: { value: 'M', type: 'paciente' },
        peso: { value: 80, type: 'paciente' },
        altura: { value: 180, type: 'paciente' },
        hospitalReferencia: { value: '12', type: 'paciente' },
        cp: { value: '28323', type: 'paciente' },
        // PHmenor735Ingreso: { value: 8, type: 'paciente' },
        FEV150: { value: 40, type: 'paciente' },
        FEVI: { value: 60, type: 'paciente' },
        exfumador: { value: true, type: 'paciente' },
        soporteVentilatorioAlta: { value: true, type: 'paciente' },
        oxigenacionDomicilioAlta: { value: true, type: 'paciente' }
        // VMIyVMNIingreso: { value: true, type: 'paciente' }
      },
      {
        ingresosPrevios: { value: true, type: 'paciente' },
        duracionIngreso: { value: 10, type: 'paciente' },
        BMIbajo: { value: 19.5, type: 'paciente' },
        BMInormal: { value: 20, type: 'paciente' },
        BMIsobrepeso: { value: 27, type: 'paciente' },
        BMIobesidad: { value: 27, type: 'paciente' },
        Charlson: { value: [], type: 'paciente', dataType: 'comboBox' }
      },
      {
        VMIingreso: { value: true, type: 'paciente' },
        VMNIingreso: { value: true, type: 'paciente' },
        fumador: { value: true, type: 'paciente' },
        PM25muExpMayor: { value: 13.23, type: 'entorno' },
        bencenoExpMenor070: { value: 0.6, type: 'entorno' },
        disponibilidadVMNIhospital: { value: true, type: 'hospital' },
        bencenoExpMayor070: { value: 0.8, type: 'entorno' },
        hospitalBajaComplejidad: { value: true, type: 'hospital' },
        arsenicoMayor06: { value: 0.7, type: 'entorno' },
        atendidoHospitalUniversitario: { value: true, type: 'hospital' },
        disponibilidadSalaNeumologia: { value: true, type: 'hospital' },
        hospitalMedianaComplejidad: { value: true, type: 'hospital' },
        arsenicoExpMenor06: { value: 0.5, type: 'entorno' },
        MayorExpPMmenor25mu: { value: 12, type: 'entorno' }
      }
    ],
    // este array debe coincidir en numero con el superior, es el label para cada bloque de datos
    dataCategories: [
      'Datos Demográficos y Generales',
      'Antecedentes y Eenfermedades Asociadas',
      'Tratamientos recibidos, Recursos hospitalarios y Exposición ambiental'
    ],
    comorbilidades: [
      { score: 1, text: 'Insuficiencia cardíaca congestiva', value: 0 },
      { score: 1, text: 'Infarto agudo del miocardio', value: 1 },
      { score: 1, text: 'Enfermedad vascular periferica', value: 2 },
      { score: 1, text: 'Enfermedad cerebral vascular', value: 3 },
      { score: 1, text: 'Demencia', value: 4 },
      { score: 2, text: 'Hemiplejia', value: 5 },
      { score: 1, text: 'Enfermedad pulmonar crónica', value: 6 },
      {
        score: 1,
        text: 'EPOC, Sindrome de distres respiratorio adquirido o enfisema',
        value: 7
      },
      { score: 1, text: 'ASMA', value: 8 },
      { score: 1, text: 'Fibrosis pulmonar', value: 9 },
      {
        score: 1,
        text: 'SAHS (Sindrome de apnea hipoventilacion asociado al Sueño',
        value: 10
      },
      { score: 1, text: 'Enfermedad del tejido conectivo', value: 11 },
      { score: 1, text: 'Artritis reumatoide u osteoartritis', value: 12 },
      { score: 1, text: 'Ulcera peptica (gástrica o duodenal)', value: 13 },
      { score: 1, text: 'Enfermedad hepática leve', value: 14 },
      { score: 3, text: 'Enfermedad hepática moderada-severa', value: 15 },
      { score: 2, text: 'Enfermedad renal moderada-severa', value: 16 },
      { score: 1, text: 'Diabetes', value: 17 },
      { score: 2, text: 'Diabetes con daño en órgano blanco', value: 18 },
      { score: 2, text: 'Diabetes con neuropatia', value: 19 },
      {
        score: 2,
        text: 'Cualquier tumor (excepto pulmon esofago pancreas o mama)',
        value: 20
      },
      { score: 2, text: 'Leucemia', value: 21 },
      { score: 2, text: 'Linfoma', value: 22 },
      {
        score: 2,
        text:
          'Cancer pulmonar, esofagico, pancreatico, o Cancer de mama en mujeres',
        value: 23
      },
      { score: 6, text: 'Tumor sólido metastásico', value: 24 },
      { score: 6, text: 'SIDA', value: 25 }
    ],
    e6: 0,
    facts: []
  }),
  computed: {
    charlson: function() {
      // `this` points to the vm instance
      return (
        (this.patientDataList[1].Charlson.value.reduce(
          (a, b) => a + b.score,
          0
        ) -
          this.scoreEdad) *
        0.9
      )
    },
    supervivenciaCharlson: function() {
      // `this` points to the vm instance
      return Math.pow(0.983, this.charlson)
    },
    imc: function() {
      // `this` points to the vm instance
      return (
        this.patientDataList[0].peso.value /
        Math.pow(this.patientDataList[0].altura.value / 100, 2)
      )
    },
    comorbilidadesValues: function() {
      var comorbilidades = this.patientDataList[1].Charlson.value.map(function(
        x
      ) {
        return x.value
      })
      return comorbilidades
    },
    enfermedadCardiaca: function() {
      return this.comorbilidadesValues.includes(0, 1, 2, 3) // todo, revisar las que faltan
    },
    FEVmenor50: function() {
      // `this` points to the vm instance
      return (
        this.patientDataList[1].duracionIngreso.value >= 10 ||
        this.patientDataList[2].VMNIingresp.value ||
        this.patientDataList[2].VMIingresp.value ||
        this.patientDataList[0].soporteVentilatorioAlta.value ||
        this.patientDataList[0].oxigenacionDomicilioAlta.value
      )
    },
    PHmenor735Ingreso: function() {
      // `this` points to the vm instance
      return this.patientDataList[2].VMIingreso.value
    },
    VMIyVMNIingreso: function() {
      // `this` points to the vm instance
      return (
        this.patientDataList[2].VMIingreso.value &&
        this.patientDataList[2].VMNIingreso.value
      )
    },
    scoreEdad: function() {
      const edad = this.patientDataList[0].edad.value
      if (edad >= 0 && edad < 40) {
        return 0
      } else if (edad >= 50 && edad < 60) {
        return 1
      } else if (edad >= 60 && edad < 70) {
        return 2
      } else if (edad >= 70 && edad < 80) {
        return 3
      } else if (edad >= 80 && edad < 90) {
        return 4
      } else {
        return 5
      }
    }
  },
  methods: {
    async storeData() {
      this.generateFacts()
      var engine = new Engine()
      console.log('Engine')
      console.log(engine)
      addRules(engine)

      // Run the engine to evaluate
      this.recomendations = []
      var events = await engine.run(this.facts)
      console.log(events)
      events.events.map(event => {
        // this.recomendations.push(event.type)
        this.recomendations.push(event.params.message)
      })

      await this.$store.commit('setLoadingFlag', true)
      var db = firebase.firestore()
      var context = this
      db.collection(firebase.auth().currentUser.uid)
        .add({
          date: new Date(),
          feedBack: this.recomendations,
          data: context.patientDataList
        })
        .then(async function() {
          context.$store.dispatch('setHistoryAsync')
          await context.$store.commit('setLoadingFlag', false)
          context.$store.commit('setsnackbarFlag', true)
          // context.$store.commit('setsnackbarText', recomendations)
          context.dialog = true
          context.e6 = 0
        })
        .catch(async function(error) {
          console.error('Error adding document: ', error)
          await context.$store.commit('setLoadingFlag', false)
          context.$store.commit('setsnackbarFlag', true)
          context.$store.commit('setsnackbarText', 'error')
        })
    },
    generateFacts() {
      this.facts = {
        // regla 5
        edad: this.patientDataList[0].edad.value,
        contaminacionAmbiental: true, // siempre hay contaminacion ambiental
        PM25µ: this.patientDataList[2].MayorExpPMmenor25mu.value,
        benceno: 1,
        arsenico: 0.6,

        // regla 7
        IMC: this.imc,

        // regla 9
        // IMC: 25,

        // regla 11
        // IMC: 25,
        enfermedadCardiaca: this.enfermedadCardiaca,
        insuficienciaCardiacaCongestiva: this.comorbilidadesValues.includes(0),
        fibrilacionFlutterAuricular: false, // rojo
        enfermedadCoronaria: false, // rojo
        infartoAgudoMiocardio: this.comorbilidadesValues.includes(1),
        enfermedadVascularPeriferica: this.comorbilidadesValues.includes(2),
        enfermedadCerebralVascular: this.comorbilidadesValues.includes(3),
        AIT: false, // rojo

        // 13
        FEV1: this.patientDataList[0].FEVI.value,
        // contaminacionAmbiental: true,
        oxigenoterapia:
          this.patientDataList[0].soporteVentilatorioAlta.value ||
          this.patientDataList[0].oxigenacionDomicilioAlta.value,
        // PM25µ: 13.23,

        // 15
        // FEV1: true,
        // contaminacionAmbiental: true,
        // oxigenoterapia: true,
        // PM25µ: true,

        // 17
        // contaminacionAmbiental: true,
        // oxigenoterapia: true,
        // PM25µ: true,

        // 19
        charlson: this.charlson,
        // insuficienciaCardiacaCongestiva: true,
        // fibrilacionFlutterAuricular: true,
        // enfermedadCoronaria: true,
        // infartoAgudoMiocardio: true,
        // enfermedadVascularPeriferica: true,
        // enfermedadCerebralVascular: true,
        // AIT: true,

        // 21
        // charlson: true,
        // insuficienciaCardiacaCongestiva: true

        // 23
        // charlson: true,
        // fibrilacionFlutterAuricular: true,

        // 25
        // charlson: true,
        // enfermedadCoronaria: true,

        // 27
        // charlson: true,
        // infartoAgudoMiocardio: true,

        // 29
        // charlson: true,
        // enfermedadVascularPeriferica: true,

        // 31
        // charlson: true,
        // enfermedadCerebralVascular: true

        // 33
        // charlson: true,
        // AIT: true

        // 35
        // charlson: true,
        demencia: this.comorbilidadesValues.includes(4),

        // 37
        // charlson: true,
        enfermedadNeurologica: false, // rojo

        // 39
        // charlson: true,
        hemiplejia: this.comorbilidadesValues.includes(5),

        // 41
        // charlson: true,
        enfermedadTejidoConectivo: this.comorbilidadesValues.includes(11),

        // 43
        // charlson: true,
        artritis: this.comorbilidadesValues.includes(12),

        // 45
        // charlson: true,
        osteoporosis: false, // rojo

        // 47
        // charlson: true,
        enfermedadDegenerativaVertebral: false, // rojo

        // 49
        // charlson: true,
        enfermedadTractoGastro: false, // rojo

        // 51
        // charlson: true,
        ulceraPeptica: this.comorbilidadesValues.includes(13),

        // 53
        // charlson: true,
        enfermedadHepaticaLeve: this.comorbilidadesValues.includes(14),

        // 55
        // charlson: true,
        enfermedadHepaticaModerada: this.comorbilidadesValues.includes(15),

        // 57
        // charlson: true,
        cirrosisHepatica: false,

        // 59
        // charlson: true,
        enfermedadRenalModerada: this.comorbilidadesValues.includes(16),

        // 61
        // charlson: true,
        // IMC: true,

        // 63
        // charlson: true,
        diabetes: this.comorbilidadesValues.includes(17),

        // 65
        // charlson: true,
        diabetesDanoOrganoBlanco: this.comorbilidadesValues.includes(18),

        // 67
        // charlson: true,
        diabetesNeuropatia: this.comorbilidadesValues.includes(19),

        // 69
        // charlson: true,
        cualquierTumorLeucemiaLinfoma: this.comorbilidadesValues.includes(20),

        // 71
        // charlson: true,
        cancerPulmonar: this.comorbilidadesValues.includes(23),

        // 73
        // charlson: true,
        tumorSolidoMetastasico: this.comorbilidadesValues.includes(24),

        // 75
        // charlson: true,
        SIDA: this.comorbilidadesValues.includes(25),

        // 77
        // charlson: true,
        ansiedadPanico: false, // rojo

        // 79
        // charlson: true,
        depresion: false, // rojo

        // 81
        // charlson: true,
        discapacidadVisual: false, // rojo

        // 83
        // charlson: true,
        discapacidadAuditiva: false, // rojo

        // 85
        // charlson: true,
        usoColirios: false, // rojo

        // 87
        // charlson: true,
        faramacosHipnoticos: false, // rojo

        // 89
        // charlson: true,
        hipertrofiaProstatica: false, // rojo

        // 91
        fumador: this.patientDataList[2].fumador.value,

        // 92
        exfumador: this.patientDataList[0].exfumador.value,

        // 93
        sinDatosTabaco: false, // rojo

        // 94
        ingresosPrevios: this.patientDataList[1].ingresosPrevios.value,

        // 96
        duracionIngresosPrevios: this.patientDataList[1].duracionIngreso.value
      }
    }
  }

}
</script>

<style lang="scss" scoped></style>
