<template>
  <div>
    <welcome />
    <services />
    <about-me />
    <GetInTouch/>
  </div>
</template>

<script>
export default {
  name: 'CoreView',

  components: {
    AboutMe: () => import('@/components/landing/AboutMe'),
    Services: () => import('@/components/landing/Services'),
    Welcome: () => import('@/components/landing/Welcome'),
    GetInTouch: () => import('@/components/landing/GetInTouch')
  }
}
</script>
