<template>
  <div>
    <LinesChart
      :options="options"
      :chart-data="datacollection"
      :height="200"
    ></LinesChart>
  </div>
</template>

<script>
import LinesChart from "@/components/clinical/charts/LinesChart.vue";

export default {
  components: {
    LinesChart,
  },
  props: {
    selectedUserHistorial: {
      type: Array,
    },
  },
  mounted() {
    //this.fillData();
    this.fillLabels();
  },
  data() {
    return {
      last30DaysDate: new Date(),
      last30DaysDateTime: 0,
      currentDate: new Date(),
      currentDateTime: 0,
      datacollection: null,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: "Peso",
            backgroundColor: "#f87979",
            data: [],
          },
          {
            label: "BMI Normal",
            backgroundColor: "#B9A5C2",
            data: [],
          },
        ],
      };
      var context = this;
      this.selectedUserHistorial.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(a.date.seconds * 1000) - new Date(b.date.seconds * 1000)
        );
      });
      this.selectedUserHistorial.forEach(function (arrayItem) {
        context.datacollection.datasets[0].data.push(
          arrayItem.data[0].peso.value
        );
        context.datacollection.datasets[1].data.push(
          arrayItem.data[1].BMInormal.value
        );

        console.log(
          new Date(arrayItem.date.seconds * 1000).toLocaleDateString("es")
        );
        context.datacollection.labels.push(
          new Date(arrayItem.date.seconds * 1000).toLocaleDateString("es")
        );
      });
    },
    fillLabels() {
      this.datacollection = {
        labels: [],
        datasets: [
          {
            label: "Peso",
            backgroundColor: "#f87979",
            data: [],
          },
          {
            label: "BMI Normal",
            backgroundColor: "#B9A5C2",
            data: [],
          },
        ],
      };
      this.currentDate = new Date();
      this.currentDateTime = this.currentDate.getTime();

      var date;
      var auxDate;
      for (var i = 30; i > 0; i--) {
        date = new Date();
        auxDate = new Date(date.setDate(date.getDate() - i));
        console.log(auxDate);
        this.datacollection.labels.push(auxDate.toLocaleDateString("es"));
        var resultado = this.selectedUserHistorial.find((item) => {
          console.log("nepe");
          console.log(
            new Date(item.date.seconds * 1000).toLocaleDateString("es")
          );
          console.log(auxDate.toLocaleDateString("es"));
          if (
            new Date(item.date.seconds * 1000).toLocaleDateString("es") ===
            auxDate.toLocaleDateString("es")
          ) {
            return item;
          }
        });
        console.log("resultado");
        console.log(resultado);
        if (resultado) {
          this.datacollection.datasets[0].data.push(
            resultado.data[0].peso.value
          );
          this.datacollection.datasets[1].data.push(
            resultado.data[1].BMInormal.value
          );
        }
        this.datacollection.datasets[0].data.push(0);
        this.datacollection.datasets[1].data.push(0);
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
  },
};
</script>
