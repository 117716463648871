export function addRules(engine) {
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'edad',
          operator: 'greaterThanInclusive',
          value: 70
        },
        {
          fact: 'contaminacionAmbiental',
          operator: 'equal',
          value: true
        },
        {
          any: [
            {
              fact: 'PM25µ', // crear variable
              operator: 'greaterThanInclusive',
              value: 13.23
            },
            {
              fact: 'benceno', // crear variable
              operator: 'greaterThan',
              value: 0
            },
            {
              fact: 'arsenico', // crear variable
              operator: 'greaterThan',
              value: 0.6
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion5',
      params: {
        message:
          'Restringir la actividad y el ejercicio al aire libre al necesario y en caso de realizarlo usar mascarilla con filtro. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 7
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'IMC',
          operator: 'lessThanInclusive',
          value: 19.5
        }
      ]
    },
    event: {
      type: 'recomendacion7',
      params: {
        message:
          'Se recomienda el ingreso a un programa nutricional que favorezca el aporte proteico adecuado y un programa gradual de ejercicio isometrico regular. SIGNOS DE ALARMA: Reduccion de la actividad, debilidad y fragilidad progresiva'
      }
    }
  })
  // 9
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'IMC',
          operator: 'greaterThanInclusive',
          value: 25
        }
      ]
    },
    event: {
      type: 'recomendacion9',
      params: {
        message:
          'Se recomienda el ingreso a un programa nutricional que favorezca una dieta hipacalorica, rica en proteinas, un programa gradual de ejercicio isometrico regular y ejercitacion cardiovascular. SIGNOS DE ALARMA: 1. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 2. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 3. Polifagia (Exceso alimentario) + poliuria (Incremento en la frecuencia de la orina) + polidipsia (Incremento en la sensacion de sed)'
      }
    }
  })
  // 11
  engine.addRule({
    conditions: {
      any: [
        {
          fact: 'IMC',
          operator: 'greaterThanInclusive',
          value: 25
        },
        {
          fact: 'enfermedadCardiaca',
          operator: 'equal',
          value: true
        },
        {
          fact: 'insuficienciaCardiacaCongestiva',
          operator: 'equal',
          value: true
        },
        {
          fact: 'fibrilacionFlutterAuricular',
          operator: 'equal',
          value: true
        },
        {
          fact: 'enfermedadCoronaria',
          operator: 'equal',
          value: true
        },
        {
          fact: 'infartoAgudoMiocardio',
          operator: 'equal',
          value: true
        },
        {
          fact: 'enfermedadVascularPeriferica',
          operator: 'equal',
          value: true
        },
        {
          fact: 'enfermedadCerebralVascular',
          operator: 'equal',
          value: true
        },
        {
          fact: 'AIT',
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion11',
      params: {
        message:
          'Se recomienda el ingreso a un programa nutricional y un programa gradual de ejercicio, consensuado con el cardiólogo. SIGNOS DE ALARMA: 1. Edema en las piernas de reciente aparición o que ha aumentado, acompañado de dificultad respiratoria o sensación de falta de aire, 2. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 3. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 4. Debilidad repentina o perdida del estado de conciencia.'
      }
    }
  })
  // 13
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'FEV1',
          operator: 'lessThanInclusive',
          value: 50
        },
        {
          fact: 'contaminacionAmbiental',
          operator: 'equal',
          value: true
        },
        {
          fact: 'oxigenoterapia',
          operator: 'equal',
          value: false
        },
        {
          fact: 'PM25µ',
          operator: 'greaterThanInclusive',
          value: 13.23
        }
      ]
    },
    event: {
      type: 'recomendacion13',
      params: {
        message:
          'Evitar camitanatas al aire libre sin mascarilla con filtro en zonas donde se prevean niveles elevados de contaminación.SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 15
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'FEV1',
          operator: 'lessThanInclusive',
          value: 50
        },
        {
          fact: 'contaminacionAmbiental',
          operator: 'equal',
          value: true
        },
        {
          fact: 'oxigenoterapia',
          operator: 'equal',
          value: true
        },
        {
          fact: 'PM25µ',
          operator: 'greaterThanInclusive',
          value: 13.23
        }
      ]
    },
    event: {
      type: 'recomendacion15',
      params: {
        message:
          'Evitar camitanatas al aire libre sin mascarilla con filtro en zonas donde se prevean niveles elevados de contaminación. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 17
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'contaminacionAmbiental',
          operator: 'equal',
          value: true
        },
        {
          fact: 'oxigenoterapia',
          operator: 'equal',
          value: true
        },
        {
          fact: 'PM25µ',
          operator: 'greaterThanInclusive',
          value: 13.23
        }
      ]
    },
    event: {
      type: 'recomendacion17',
      params: {
        message:
          'Restringir las salidas a las estrictamente necesarias en zonas donde se prevean niveles elevados de contaminación, y se recomienda mascarilla con valvula y filtro en caso de poder ser acoplada. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 19
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'insuficienciaCardiacaCongestiva', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'fibrilacionFlutterAuricular', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'enfermedadCoronaria', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'infartoAgudoMiocardio', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'enfermedadVascularPeriferica', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'enfermedadCerebralVascular', // crear variable
              operator: 'equal',
              value: true
            },
            {
              fact: 'AIT', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion19',
      params: {
        message:
          'Restringir las salidas a las estrictamente necesarias en zonas donde se prevean niveles elevados de contaminación, y se recomienda mascarilla con valvula y filtro en caso de poder ser acoplada. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 21
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'insuficienciaCardiacaCongestiva', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion21',
      params: {
        message:
          'Verificar la presencia o evolución del edema en las piernas. Si presenta edema residual o estable (que no aumenta) en miembros inferiores, sin dificultad respiratoria. Intentar mantener los pies el alto de forma intermitente a lo largo del dia, mantener la piel hidratada y masajear de distal a proximal para favorecer el drenaje linfático. SIGNOS DE ALARMA: 1. Edema en las piernas de reciente aparición o que ha aumentado, acompañado de dificultad respiratoria o sensación de falta de aire, 2. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 3. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 4. Debilidad repentina o perdida del estado de conciencia.'
      }
    }
  })
  // 23
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'fibrilacionFlutterAuricular', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion23',
      params: {
        message:
          'Además de la recomendación de seguimiento por cardiología/medico de familia y revisión periódica de su estado de coagulación. SIGNOS DE ALARMA: 1. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 2. Debilidad repentina, deficit neurológico o perdida del estado de conciencia.'
      }
    }
  })
  // 25
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadCoronaria', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion25',
      params: {
        message:
          'Además de la recomendación de seguimiento por cardiología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, dieta y ejercicio regular pautado. SIGNOS DE ALARMA: 1. Edema en las piernas de reciente aparición o que ha aumentado, acompañado de dificultad respiratoria o sensación de falta de aire, 2. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 3. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 4. Debilidad repentina deficit neurológico o perdida del estado de conciencia.'
      }
    }
  })
  // 27
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'infartoAgudoMiocardio', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion27',
      params: {
        message:
          'Además de la recomendación de seguimiento por cardiología/medico de familia y revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 2. Debilidad repentina, deficit neurológico o perdida del estado de conciencia.'
      }
    }
  })
  // 29
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadVascularPeriferica', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion29',
      params: {
        message:
          'Además de la recomendación de seguimiento por cardiología/medico de familia, revisión periódica revisión periódica de su estado de metabolico y de coagulación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Edema en las piernas de reciente aparición o que ha aumentado, acompañado de dificultad respiratoria o sensación de falta de aire, 2. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 3. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 4. Debilidad repentina deficit neurológico o perdida del estado de conciencia.'
      }
    }
  })
  // 31
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadCerebralVascular', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion31',
      params: {
        message:
          'Además de la recomendación de seguimiento por neurologia-cardiología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, rehabilitación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Deficit neurológico nuevo o agravado: Paralisis, perdida de la fuerza o la sensibilidad de alguna zona del cuerpo, deterioro cognitivo, alteracion del estado de conciencia, desviación de la comisura labial, dificultad para hablar, alteraciones agudas en la percepcion visual, o de alguno de los sentidos, etc), 2. Perdida del estado de conciencia.'
      }
    }
  })
  // 33
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'AIT', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion33',
      params: {
        message:
          'Además de la recomendación de seguimiento por neurologia-cardiología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, dieta y ejercicio regular pautado personalizado SIGNOS DE ALARMA: 1. Deficit neurológico nuevo o agravado: Paralisis, perdida de la fuerza o la sensibilidad de alguna zona del cuerpo, deterioro cognitivo, alteracion del estado de conciencia, desviación de la comisura labial, dificultad para hablar, alteraciones agudas en la percepcion visual, o de alguno de los sentidos, etc), 2. Perdida del estado de conciencia.'
      }
    }
  })
  // 35
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'demencia', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion35',
      params: {
        message: ''
      }
    }
  })
  // 37
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadNeurologica', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion37',
      params: {
        message:
          'Además de la recomendación de seguimiento por neurologia/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, rehabilitación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Perdida funcional (autonomía y autocuidado) acelerada. 2. Fiebre 3. Dificultad respiratoria 4. Deterioro del estado de conciencia'
      }
    }
  })
  // 39
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'hemiplejia', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion39',
      params: {
        message:
          'Además de la recomendación de seguimiento por neurologia/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, rehabilitación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Deficit neurológico nuevo o agravado: Paralisis, perdida de la fuerza o la sensibilidad de alguna zona del cuerpo, deterioro cognitivo, alteracion del estado de conciencia, desviación de la comisura labial, dificultad para hablar, alteraciones agudas en la percepcion visual, o de alguno de los sentidos, etc), 2. Perdida del estado de conciencia. 3. Perdida funcional (autonomía y autocuidado) acelerada.'
      }
    }
  })
  // 41
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadTejidoConectivo', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion41',
      params: {
        message:
          'Además de la recomendación de seguimiento por reumatologia/medico de familia, revisión periódica revisión periódica de su estado metabolico y de coagulación. SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia previa 2.-Aparición de nuevos sintomas 3.-Fiebre'
      }
    }
  })
  // 43
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'artritis', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion43',
      params: {
        message:
          'Además de la recomendación de seguimiento por reumatologia/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, Rehabilitacion y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Persistencia de sintomas, dolor y limitacion funcional a pesar del tratamiento 2. Disnea o dificultad respiratoria súbita 3. Dolor precordial 4. Fiebre 5. Deficit neurológico o alteracion del estado de conciencia 6. Dolor abdominal agudo 7. Alteracion del estado de ánimo, ansiedad o depresión 8. Molestias oculares, irritacion y sensación de ojo seco de reciente aparicion'
      }
    }
  })
  // 45
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'osteoporosis', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion45',
      params: {
        message:
          'Además de la recomendación de seguimiento por endocrinologia/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, rehabilitación, tomar el sol diariamente, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Caida desde la propia altura sin causa aparente 2. Dolor articular agudo e intenso 3. Debilidad repentina, limitacion funcional para la movilidad de una extremidad con o sin deformidad.'
      }
    }
  })
  // 47
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadDegenerativaVertebral', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion47',
      params: {
        message:
          'Además de la recomendación de seguimiento por traumatologia/medico de familia, revisión periódica, rehabilitación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1. Persistencia de sintomas, dolor y limitacion funcional a pesar del tratamiento y la rehabilitación'
      }
    }
  })
  // 49
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadTractoGastro', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion49',
      params: {
        message:
          'Además de la recomendación de seguimiento por gastroenterología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, endoscopia digestiva diagnostica pautada, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1.-Dolor agudo epigástrico (debajo del esternon) 2. Sensacion de ardor y reflujo de acido o alimentos digeridos 3.-Vomito de sangre fresca o sangre digerida (oscura maloliente) 4.-Cambio en las caracteristicas y coloracion de las heces (fetidas y oscuras)'
      }
    }
  })
  // 51
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'ulceraPeptica', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion51',
      params: {
        message:
          'Además de la recomendación de seguimiento por gastroenterología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, endoscopia digestiva diagnostica pautada, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1.-Dolor agudo epigástrico (debajo del esternon) 2. Sensacion de ardor y reflujo de acido o alimentos digeridos 3.-Vomito de sangre fresca o sangre digerida (oscura maloliente) 4.-Cambio en las caracteristicas y coloracion de las heces (fetidas y oscuras) 5.-Palidez mucocutanea y debilidad general'
      }
    }
  })
  // 53
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadHepaticaLeve', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion53',
      params: {
        message:
          'Además de la recomendación de seguimiento por gastroenterología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, ecografia hepática diagnostica inicial, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: SIGNOS DE ALARMA: 1.-Coloracion amarilla de piel y escleras (ojos) 2.- Fiebre 3.-Dolor abdominal agudo 4.-Vomito de sangre 5.-Cambio en las caracteristicas y coloración de las heces 6.-Deterioro del estado de conciencia'
      }
    }
  })
  // 55
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadHepaticaModerada', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion55',
      params: {
        message:
          'Además de la recomendación de seguimiento por gastroenterología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, ecografia hepática diagnostica inicial, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1.-Coloracion amarilla de piel y escleras (ojos) 2.- Fiebre 3.-Dolor abdominal agudo 4.-Vomito de sangre 5.-Cambio en las caracteristicas y coloración de las heces 6.-Deterioro del estado de conciencia'
      }
    }
  })
  // 57
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'cirrosisHepatica', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion57',
      params: {
        message:
          'Además de la recomendación de seguimiento por gastroenterología/medico de familia, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, ecografia abdominal diagnostica inicial, dieta y ejercicio regular pautado personalizado. Ajuste de pauta terapeutica al grado de compromiso funcional hepático. SIGNOS DE ALARMA: 1.- Coloracion amarilla de piel y escleras (ojos) 2.- Fiebre 3.-Dolor abdominal agudo 4.-Vomito de sangre 5.-Cambio en las caracteristicas y coloración de las heces 6.-Deterioro del estado de conciencia'
      }
    }
  })
  // 59
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'enfermedadRenalModerada', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion59',
      params: {
        message:
          'Además de la recomendación de seguimiento por nefrología/medico de familia, revisión periódica analítica de su estado bioquimico, electrolítico, metabolico y de coagulación, proteinuria 24h, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1.- Edema palpebral (parpados) en las mañanas que resuelve a lo largo del dia y se traslada a las piernas y zonas de declive 2.- Cambios en la frecuencia, cantidad, o coloracion de la orina 3.-Ardor con la micción 4.-Fiebre'
      }
    }
  })
  // 61
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'IMC', // crear variable
              operator: 'greaterThanInclusive',
              value: 30
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion61',
      params: {
        message:
          'Se recomienda el ingreso a un programa nutricional que favorezca una dieta hipacalorica, rica en proteinas, un programa gradual de ejercicio isometrico regular y ejercitacion cardiovascular. SIGNOS DE ALARMA: 1. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 2. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 3. Polifagia (Exceso alimentario) + poliuria (Incremento en la frecuencia de la orina) + polidipsia (Incremento en la sensacion de sed)'
      }
    }
  })
  // 63
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'diabetes', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion63',
      params: {
        message:
          'Seguir las recomendaciones terapeuticas pautadas para mantener el adecuado control glicemico, revisión periódica analítica de su estado bioquimico, metabolico y de coagulación, dieta y ejercicio regular pautado personalizado. SIGNOS DE ALARMA: 1.-Aumento en la frecuencia y volumen de orina 2.-Incremento en la sensacion de sed 3.-Aumento del apetito 4.-Sintomas asociados con la baja de azucar: Sudoración profusa, palidez mucocutanea, debilidad y sensacion de desvanecimiento.'
      }
    }
  })
  // 65
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'diabetesDanoOrganoBlanco', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion65',
      params: {
        message:
          'Si el órgano afectado es Corazón: 1. Verificar la presencia o evolución del edema en las piernas. 2. Si se acompaña de dificultad respiratoria o sensación de falta de aire debe consultar a al medico de manera preferente o por urgencias. 3. Si presenta edema residual o estable (que no aumenta) en miembros inferiores, sin dificultad respiratoria. Intentar mantener los pies el alto de forma intermitente a lo largo del dia, mantener la piel hidratada y masajear de distal a proximal para favorecer el drenaje linfático. SIGNOS DE ALARMA (Ademas de los descritos para la opcion Diabetes): 1. Edema en las piernas de reciente aparición o que ha aumentado, acompañado de dificultad respiratoria o sensación de falta de aire, 2. Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), 3. Claudicación intermitente (Dolor intenso, no articular, tipicamente en las pantorrilas al caminar y que mejora con el reposo), 4. Debilidad repentina o perdida del estado de conciencia. Si el órgano afectado es Riñón: 1. SIGNOS DE ALARMA (Ademas de los descritos para la opcion Diabetes): 1.- Edema palpebral (parpados) en las mañanas que resuelve a lo largo del dia y se traslada a las piernas y zonas de declive 2.- Cambios en la frecuencia, cantidad, o coloracion de la orina 3.-Ardor, molestia o sensación de obstruccion al orinar 4.-Fiebre'
      }
    }
  })
  // 67
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'diabetesNeuropatia', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion67',
      params: {
        message:
          '1. Verificar la presencia o evolución del edema en las piernas. 2. Verificar estado de hidratacion e integridad (presencia de heridas) en la piel sobretodo en zonas con sensibilidad disminuida (pies, talones, gluteos, sacro o puntos de apoyo en personas con movilidad reducida). SIGNOS DE ALARMA: 1.-Heridas en piel con sensibilidad alterada'
      }
    }
  })
  // 69
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'equal',
          value: 3
        },
        {
          any: [
            {
              fact: 'cualquierTumorLeucemiaLinfoma', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion69',
      params: {
        message:
          '1.-Seguir todas las recomendaciones del equipo de oncologia y cuidado paliativo 2.-En caso de asociarse a inmunodeficiencia antener las medidas de seguridad y distanciamiento social para evitar sobreinfecciones. SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia previa 2.-Aparición de nuevos sintomas 3.-Fiebre'
      }
    }
  })
  // 71
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'cancerPulmonar', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion71',
      params: {
        message:
          '1.-Seguir todas las recomendaciones del equipo de oncologia y cuidado paliativo 2.-En caso de asociarse a inmunodeficiencia antener las medidas de seguridad y distanciamiento social para evitar sobreinfecciones. SIGNOS DE ALARMA: 1.-Deterioro del estado de conciencia 2.-Empeoramiento de sintomatologia previa 3.-Aparición de nuevos sintomas 4.-Vomito con sangre 5.-Cambios en las características y coloración del esputo 6.-Fiebre 7.-Coloracion amarilla de la piel y mucosas 8.-Dolor abdominal agudo'
      }
    }
  })
  // 73
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'tumorSolidoMetastasico', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion73',
      params: {
        message:
          '1.-Seguir todas las recomendaciones del equipo de oncologia y cuidado paliativo 2.-En caso de asociarse a inmunodeficiencia antener las medidas de seguridad y distanciamiento social para evitar sobreinfecciones. SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia previa 2.-Aparición de nuevos sintomas 3.-Fiebre'
      }
    }
  })
  // 75
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'SIDA', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion75',
      params: {
        message:
          '1.-Seguir todas las recomendaciones del equipo de oncologia y cuidado paliativo 2.-En caso de asociarse a inmunodeficiencia antener las medidas de seguridad y distanciamiento social para evitar sobreinfecciones. SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia previa 2.-Aparición de nuevos sintomas 3.-Fiebre'
      }
    }
  })
  // 77
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'ansiedadPanico', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion77',
      params: {
        message:
          '1.-Seguir las recomendaciones del medico tratante 2.-Mantener una red de apoyo social familiar y social de apoyo cercana, que sea capáz de identificar cambios en el estado de ánimo. SIGNOS DE ALARMA: 1.-Dolor precordial (dolor en el pecho irradiado al brazo en reposo o que mejora con el reposo), palpitaciones (sensacion que el corazon palpita rapido y fuerte), sensación de falta de aire, hiperventilacion (respiracion profunda/superficial y rapida) prolongada persistente 2.-Empeoramiento de sintomatologia previa 2.-Aparición de nuevos sintomas'
      }
    }
  })
  // 79
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'depresion', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion79',
      params: {
        message:
          '1.-Seguir las recomendaciones del medico tratante 2.-Mantener una red de apoyo social familiar y social de apoyo cercana, que sea capáz de identificar cambios en el estado de ánimo. SIGNOS DE ALARMA: 1.-Debilidad repentina, 2.-Deterioro cognitivo o perdida del estado de conciencia 3.-Incremento o no mejoría despues de 2 semanas de tratamiento de la Astenia (Cansancio excesivo) Adinamia (Debilidad excesiva) y Anorexia (perdida del apetito).'
      }
    }
  })
  // 81
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'discapacidadVisual', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion81',
      params: {
        message:
          '1.-Revisar periodicamente la agudeza visual y la formula de las gafas en caso de requerirlas. SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia ocular previa 2.-Aparición de nuevos sintomas oculares 3.-Fiebre 4.-Dolor ocular agudo 5.-Enrogecimiento ocular 6.-Sensacion de arenilla o sequedad ocular 7.-Aumento o cambios respecto de la produccion de lagañas'
      }
    }
  })
  // 83
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'discapacidadAuditiva', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion83',
      params: {
        message:
          '1.-Revisar periodicamente la agudeza auditiva y el funcionamiento/mantenimiento de los dispositivos de amplificacion auditiva (audifonos). SIGNOS DE ALARMA: 1.-Empeoramiento de sintomatologia auditiva previa. 2.-Aparición de nuevos sintomas relacionados con la audición o el equilibrio 3.-Fiebre 4.-Vertigo 5.-Percepción de pitidos internos o ruidos molestos en el oido'
      }
    }
  })
  // 85
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'usoColirios', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion85',
      params: {
        message:
          'Verificar pauta indicada por su médico tratante y necesidad real de continuar la medicacion de forma crónica. SIGNOS DE ALARMA: 1. Enrogecimiento ocular, secreción o sensación de cuerpo extraño en el ojo 2. Dolor ocular 3. Perdida o alteracion de la agudeza visual 4. Inflamacion ocular o palpebral'
      }
    }
  })
  // 87
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'faramacosHipnoticos', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion87',
      params: {
        message:
          'Seguir estrictamente las recomnedaciones del médico tratante respecto del uso de estos medicamentos, no usar mas de la dosis recomendada. Verificar el efecto sobre el estado de alerta y evitar actividades que requieran un alto estado de atención y alerta, como conducir. SIGNOS DE ALARMA: 1.-Exceso de somnolencia y perdida del estado de alerta, cuando condiciona las actividades de la vida diaria (Dormirse en cualquier parte, en cualquier momento) 2.-Apneas frecuentes y prolongadas (episodios de ausencia de respiracion) durante el sueño'
      }
    }
  })
  // 89
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'charlson',
          operator: 'greaterThanInclusive',
          value: 3
        },
        {
          any: [
            {
              fact: 'hipertrofiaProstatica', // crear variable
              operator: 'equal',
              value: true
            }
          ]
        }
      ]
    },
    event: {
      type: 'recomendacion89',
      params: {
        message:
          'Seguir las recomendaciones terapeuticas de su médico tratante, revision periodica de valores analiticos, estudios de imagen y diagnostico (biopsia) iniciales en caso de ser necesarios. SIGNOS DE ALARMA: 1. Incremento en la frecuencia de la micción 2.-Sensacion de obstruccion a la salida de la orina 3.-Ardor al orinar 4.-Fiebre'
      }
    }
  })
  // 91
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'fumador', // crear variable
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion91',
      params: {
        message:
          'Solicite consejería antitabaco e Ingrese en un programa para dejar de fumar y siga sus recomendaciones. Recuerde que el daño pulmonar del cigarrillo sobre el pulmón es acumulativo y dificilmente reversible. ¡Proteja su reserva pulmonar!'
      }
    }
  })
  // 92
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'exfumador', // crear variable
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion92',
      params: {
        message:
          'Mantengase alejado de ambientes con tabaco. Recuerde que el daño pulmonar del cigarrillo sobre el pulmón es acumulativo y dificilmente reversible. ¡Proteja su reserva pulmonar! Si nunca a fumado no lo haga y si fumo previamente, no vuelva ha hacerlo'
      }
    }
  })
  // 93
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'sinDatosTabaco', // crear variable
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion93',
      params: {
        message:
          'Mantengase alejado de ambientes con tabaco. Recuerde que el daño pulmonar del cigarrillo sobre el pulmón es acumulativo y dificilmente reversible. ¡Proteja su reserva pulmonar! 1.-Si fuma Solicite consejería antitabaco e Ingrese en un programa para dejar de fumar y siga sus recomendaciones. 2.-Si nunca a fumado no lo haga. 3.-Si fumo previamente, no vuelva ha hacerlo'
      }
    }
  })
  // 94
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'ingresosPrevios', // crear variable
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion94',
      params: {
        message:
          'La presencia de ingresos previos es un marcador claro de vulnerabilidad, 1.-Siga atentamente las recomendaciones de su médico tratante y asista a las revisiones periodicas que le son programadas. 2.-Preste especial atencion a la calidad de aire que respira, 3.-Mantenga distancia de seguridad con personas sintomaticas respiatorias que padecen infecciones respiratorias altas 4.-En pandemia, restrinja sus contactos y siempre con mascarilla 5.- No fume. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
  // 96
  engine.addRule({
    conditions: {
      all: [
        {
          fact: 'duracionIngresosPrevios', // crear variable
          operator: 'equal',
          value: true
        }
      ]
    },
    event: {
      type: 'recomendacion96',
      params: {
        message:
          'El haber tenido un ingreso hospitalario previo reciente de mas de 10 dias por exacerbacion respiratoria, es un marcador claro de vulnerabilidad, mucho mas si ha requerido soporte ventilatorio de cualquier tipo. 1.-Siga atentamente las recomendaciones de su médico tratante y asista a las revisiones periodicas que le son programadas. 2.-Preste especial atencion a la calidad de aire que respira, 3.-Mantenga distancia de seguridad con personas sintomaticas respiatorias que padecen infecciones respiratorias altas 4.-En pandemia, restrinja sus contactos y siempre con mascarilla 5.- No fume. SIGNOS DE ALARMA: 1. Incremento de la Disnea (Falta de aire) 2. Incremento de la cantidad del esputo (secrecion mucosa que sale por la boca al toser) 3. Cambio en la coloración del esputo (secrecion mucosa que sale por la boca al toser) 4. Fiebre (Temperatura corporal superior a 38.5ºC)'
      }
    }
  })
}
