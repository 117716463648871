import { mutations as types } from './types'
import i18n from '@/i18n'

const mutations = {
  [types.CHANGE_LANGUAGE] (state, payload) {
    i18n.locale = payload
    state.lang = payload
  },
  setLoadingFlag (state, loading) { state.loading = loading },
  setsnackbarFlag (state, snackbar) { state.snackbar = snackbar },
  setsnackbarText (state, snackbarText) { state.snackbarText = snackbarText },
  setUser (state, user) { state.user = user },
  setHistory (state, history) { state.history = history }
}

export default mutations
