<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'white'"
    fixed
    flat
  >
    <v-spacer />
    <v-slide-x-transition>
      <v-img
        v-if="showLogo"
        :src="require('@/assets/airbuddytext.jpg')"
        class="shrink"
        contain
        height="50"
      />
    </v-slide-x-transition>
    <v-spacer />

    <!-- <v-btn @click="swipeLanguage()" class="ma-2" tile outlined> </v-btn> -->
    <v-btn
      v-scroll="onScroll"
      :color="!isScrolling ? 'transparent' : 'grey'"
      icon
      rounded
      depressed
      @click="logOut"
      v-if="$route.name != 'LoginView' && $route.name != 'LandingView'"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import firebase from 'firebase'
import { mapMutations, mapGetters } from 'vuex'
import { mutations, getters } from '@/store/types'
export default {
  name: 'AppBar',
  computed: {
    ...mapGetters([getters.LANGUAGE])
  },
  data: () => ({
    showLogo: false,
    isScrolling: false
  }),
  methods: {
    ...mapMutations([mutations.CHANGE_LANGUAGE]),
    logOut() {
      firebase.auth().signOut()
    },
    swipeLanguage() {
      this.$vuetify.lang.current = this.language === 'es' ? 'en' : 'es'
      this.changeLanguage(this.language === 'es' ? 'en' : 'es')
    },
    onScroll() {
      if (this.$route.name === 'LandingView') {
        const offset = window.pageYOffset
        this.isScrolling = offset > 50
        this.showLogo = offset > 200
      } else {
        this.isScrolling = true
        this.showLogo = true
      }
    }
  }
}
</script>
