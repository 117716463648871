<template>
  <div>
    {{ selectRol }}
    {{ selectBank }}

    <v-card class="mx-auto" max-width="500" outlined elevation="20">
      <v-progress-linear
        indeterminate
        color="secondary"
        v-if="loading"
      ></v-progress-linear>
      <v-container pa-8>
        <v-card-title>{{ $t('signUp') }}</v-card-title>
        <div v-if="signUpCompleted">
          {{ $t('backToLoginText') }}
        </div>
        <v-form v-if="!signUpCompleted" ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                id="userInput"
                v-model="userData.user"
                :rules="userRules"
                :label="translatedUser"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                id="passInput"
                v-model="userData.pass"
                :rules="passRules"
                :counter="30"
                :label="translatedPass"
                required
                type="password"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                id="passInput"
                v-model="userData.passConfirmation"
                :rules="passRules"
                :counter="30"
                :label="$t('confirmPassword')"
                required
                type="password"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <v-text-field
                id="nameInput"
                v-model="userData.name"
                :label="$t('fullName')"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <v-text-field
                id="emailInput"
                v-model="userData.email"
                :rules="emailRules"
                :counter="30"
                :label="$t('email')"
                required
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  id="loginButton"
                  block
                  color="secondary"
                  dark
                  @click="userSignup"
                  >{{ $t('createAccount') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
        <v-row v-if="signUpCompleted">
          <v-col cols="12">
            <v-btn
              id="loginButton"
              block
              color="secondary"
              dark
              @click="$router.push('/')"
            >
              {{ $t('backToLogin') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
// import { mapActions } from 'vuex'

export default {
  name: 'SignUp',
  data: () => ({
    userData: {
      user: '23456789D',
      pass: 'covid1984',
      passConfirmation: 'garfield321',
      email: 'enrique.sanchez@finweg.com',
      name: 'Enrique Sanchez',
      phone: '+34659781615',
      bankId: '',
      blockcheqContract: '',
      blockbankContract: '',
      customerContract: '',
      blockcheqUrl: ''
    },
    userRules: [v => !!v || 'User is required'],
    passRules: [v => !!v || 'Password is required'],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    snackbarText: 'Error',
    snackbarColor: 'error',
    snackbar: false,
    loading: false,
    banks: '',
    roles: '',
    selectBank: {},
    selectRol: {},
    signUpCompleted: false
  }),
  methods: {
  },
  computed: {
    // a computed getter
    translatedUser: function () {
      // `this` points to the vm instance
      return this.$t('user')
    },
    translatedPass: function () {
      // `this` points to the vm instance
      return this.$t('pass')
    }
  }
}
</script>
