<template>
  <v-app>
    <AppBar />
    <v-main>
      <v-snackbar v-model="this.$store.state.snackbar" :timeout="5000">
        {{ $store.state.snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="$store.commit('setsnackbarFlag', false)"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import firebase from 'firebase'
import AppBar from '@/components/app/AppBar'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    drawer: true,
    snackbar: true
  }),
  components: {
    AppBar
  },
  computed: {
    ...mapState(['user'])
  },
  created () {
    this.createAuthlistener()
  },
  methods: {
    ...mapActions(['fetchUser']),
    createAuthlistener () {
      var context = this
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          await context.$store.commit('setUser', user)
          await context.$store.dispatch('setHistoryAsync')
          console.log('user logged')
          console.log(user)
          // context.$router.push('/home')
          // User is signed in.
          // ...
        } else {
          // User is signed out.
          await context.$store.commit('setUser', null)
          context.$router.push('/')
          // ...
        }
      })
    },
    getDocuments () {

    }
  }
}
</script>
