import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/Login.view.vue'
import SignUpView from '@/views/SignUp.view.vue'
import HomeView from '@/views/Home.view.vue'
import ClinicalView from '@/views/Clinical.view.vue'
import LandingView from '@/views/Landing.view.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/SignUp',
    name: 'SignUpView',
    component: SignUpView,
    meta: {
      hideNavBar: true
    }
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/',
    name: 'LandingView',
    component: LandingView
  },
  {
    path: '/clinical',
    name: 'ClinicalView',
    component: ClinicalView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
