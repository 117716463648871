<template>
  <v-card>
    <v-container>
      <span
        :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-1']"
        class="font-weight-light"
      >
        Listado de Pacientes
      </span>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Nombre</th>
              <th class="text-left">ID</th>
              <th class="text-left">Edad</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in users"
              :key="item.name"
              @click="showUserClinicalData(item)"
            >
              <td>{{ item.name }}</td>
              <td>{{ item.NIE }}</td>
              <td>{{ item.age }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
    },
  },
  methods: {
    showUserClinicalData(user) {
      this.$emit("selected-user", user);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
