<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container>
            <div class="text-overline mb-4">Datos Demográficos y Generales</div>

            <v-row v-for="(item, index) in data.data" v-bind:key="index">
              <v-col cols="12"> {{ dataCategories[index] }}</v-col>
              <v-col
                cols="6"
                lg="3"
                v-for="(value, key) in item"
                v-bind:key="key"
              >
                <div v-if="typeof value.value === 'boolean'">
                  <v-checkbox
                    disabled
                    :label="$t(key)"
                    v-model="value.value"
                  ></v-checkbox>
                </div>
                <div v-else>
                  <v-text-field
                    disabled
                    :label="$t(key)"
                    v-model="value.value"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container>
            <div class="text-overline mb-4">Feedback recibido</div>
            <v-row v-for="(item, index) in data.feedBack" v-bind:key="index">
              <v-col
                ><span>{{ item }}</span></v-col
              >
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      dataCategories: [
        'Datos Demográficos y Generales',
        'Antecedentes y Eenfermedades Asociadas',
        'Tratamientos recibidos, Recursos hospitalarios y Exposición ambiental'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
