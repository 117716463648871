<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="8">
        <DataInput />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12" sm="8">
        <h1>Historial</h1>
        <History />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataInput from '../components/home/DataInput'
import History from '../components/home/History'
export default {
  name: 'HomeView',
  components: {
    DataInput,
    History
  }
}
</script>
