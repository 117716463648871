<template>
  <v-card>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">Historial de Usuario</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in historial"
                :key="item.name"
                @click="showHistorialEntry(item)"
              >
                <td>{{ new Date(item.date.seconds*1000)  }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import firebase from 'firebase'

export default {
  props: {
    id: {
      type: String
    }
  },
  async mounted() {
    await this.getHistorial()
    this.historial.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000)
        );
      });
  },
  data() {
    return {
      historial: []
    }
  },
  watch: {
    id: function() {
      this.getHistorial()
    }
  },
  methods: {
    async getHistorial() {
      var context = this
      var db = firebase.firestore()
      await db
        .collection(this.id)
        .get()
        .then(async function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
            var entry = doc.data()
            entry.id = doc.id
            context.historial.push(entry)
          })
        })
        .catch(async function(error) {
          console.log(error)
        })
    },
    showHistorialEntry(entry) {
      this.$emit('selected-entry', entry)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
