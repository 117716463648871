<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <Login />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from '../components/login/Login'
export default {
  name: 'LoginView',
  components: {
    Login
  }
}
</script>
